

















































































































































import { Component, Vue } from "vue-property-decorator";
import Auth, { login } from "@/store/modules/Auth";

@Component
export default class Login extends Vue {
  errorMsg = "Error Logging in: Please try again...";
  visiblePass = false;
  validReset = false;
  resetBusy = false;
  forgotPassEmail = null;
  alert = false;
  forgotPassword = false;
  valid = false;
  get getCredentials(): login {
    return Auth.getLoginCredentials;
  }
  get getLoading(): boolean {
    return Auth.loading.login;
  }
  get getRest(): boolean {
    return Auth.loading.reset;
  }
  forgotPass(): void {
    this.forgotPassword = !this.forgotPassword;
  }

  async submitForgotEmail(): Promise<void> {
    this.resetBusy = true;
    await Auth.requestReset().catch((err) => {
      console.log("Error submitting resetPassword", err);
      return Promise.reject(err);
    });
    this.errorMsg = "Reset Password Email Sent!";
    this.alert = true;

    this.resetBusy = false;
    this.forgotPassword = false;
  }

  async tryLogin(): Promise<void> {
    try {
      this.alert = false;
      await Auth.tryLogin();
    } catch (err) {
      if (err.isAxiosError) {
        this.errorMsg = err.toJSON().message;
      }
      this.alert = true;
    } finally {
      if (Auth.isLoggedIn) {
        this.alert = false;
        this.$router.push({
          name: "dash.stations",
        });
      } else {
        this.alert = true;
      }
    }
  }
  updateEmail(email: string): void {
    //console.log("Test :", email);
    Auth.setLoginCredentials({ email: email });
  }
  updatePassword(pass: string): void {
    //console.log("Test :", pass);
    Auth.setLoginCredentials({ password: pass });
  }
  passwordRules = [
    (v: string): boolean | string =>
      (v && v.length >= 5) || "Password requires length of 5",
    (v: string): boolean | string =>
      /.(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
  ];
  mounted(): void {
    if (this.$refs.loginForm) {
      (this.$refs.loginForm as any).validate();
    }
  }
}
