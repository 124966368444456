






























import { Component, Vue } from "vue-property-decorator";

@Component
export default class ConfirmEmail extends Vue {
  errors: Error[] = [];

  get anyError(): boolean {
    return this.errors.length > 0;
  }

  async sendConfirmation(): Promise<void> {
    try {
      if (
        this.$route.query.userId === undefined ||
        this.$route.query.code === undefined
      ) {
        const error = new Error("Missing query parameters...");
        this.errors.push(error);
        return Promise.reject(error);
      }
      await this.$axiosNoAuth
        .post("api/Authenticate/confirmEmail", {
          userId: this.$route.query.userId,
          code: this.$route.query.code,
        })
        .catch((err) => {
          console.log("Error sending email confirm!", err);
          return Promise.reject(err.response.data);
        });
      this.$router.push({
        name: "Login",
      });
    } catch (err) {
      console.error("Pushing error: ", err);
      this.errors.push(err);
      return Promise.reject(err);
    }
  }

  async mounted(): Promise<void> {
    await this.sendConfirmation().catch((err) => {
      console.log("Error sending: ", err);
    });
  }
}
