import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import { AxiosError } from "axios";
export interface login {
  [field: string]: string | null;
  email: string | null;
  password: string | null;
}
export interface register {
  [field: string]: string | null;
  password: string | null;
  passwordConfirm: string | null;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  phoneNumber: string | null;
}
export interface loading {
  register: boolean;
  login: boolean;
  reset: boolean;
}
@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  loading: loading = {
    register: false,
    login: false,
    reset: false,
  };
  loginCredentials: login = {
    password: null,
    email: null,
  };
  registerCredentials: register = {
    password: null,
    email: null,
    lastName: null,
    firstName: null,
    phoneNumber: null,
    passwordConfirm: null,
  };
  accessToken: string | null = null;
  authenticated = false;
  expiration: string | null = null;

  get getLoading() {
    return this.loading;
  }

  get getLoginCredentials(): login {
    return this.loginCredentials;
  }
  get getRegisterCredentials(): register {
    return this.registerCredentials;
  }
  @Mutation
  setLoginCredentials(login: { [key: string]: string | null }) {
    this.loginCredentials = {
      ...this.loginCredentials,
      ...login,
    };
  }
  @Mutation
  setRegisterCredentials(register: { [key: string]: string | null }) {
    this.registerCredentials = {
      ...this.registerCredentials,
      ...register,
    };
  }
  get getLoggedIn(): {
    expiration: string | null;
    accessToken: string | null;
    authenticated: boolean;
  } {
    return {
      accessToken: this.accessToken,
      expiration: this.expiration,
      authenticated: this.authenticated,
    };
  }
  @Mutation
  setLoggedIn({
    expiration,
    accessToken,
  }: {
    expiration: string;
    accessToken: string;
  }) {
    this.accessToken = accessToken;
    this.expiration = expiration;
    this.authenticated = true;

    localStorage.setItem(
      "plant-sickness/login",
      JSON.stringify({
        accessToken: this.accessToken,
        expiration: this.expiration,
        authenticated: this.authenticated,
      })
    );
  }
  
  @Mutation
  removeLoggedIn() {
    localStorage.removeItem("plant-sickness/login");
    this.accessToken = null;
    this.expiration = null;
    this.authenticated = false;
  }
  get isLoggedIn(): boolean {
    //add time check here for timestamp?
    return this.authenticated;
  }

  @Action({rawError: true})
  async requestReset(): Promise<void> {
    try {
      
      this.loading.login = true;
      this.loading.reset = true;
      
      const res = await axiosNoAuth
        .post("/api/Authenticate/ResetPassword", {
          email: this.getLoginCredentials.email
        })
        .catch((err) => {
          //check types of errors heree
          return Promise.reject(err);
        });
      
      console.log("Done calling login", res.data);
    } catch (err) {
      console.log("Error with login: ", err);
      return Promise.reject(err);
    } finally {
      this.loading.login = false;
    }
  }
  @Action({ rawError: true })
  async tryLogin(): Promise<void> {
    try {
      this.loading.login = true;
      const res = await axiosNoAuth
        .post("/api/Authenticate/login", {
          username: this.getLoginCredentials.email,
          password: this.getLoginCredentials.password,
        })
        .catch((err) => {
          //check types of errors heree
          return Promise.reject(err);
        });
      this.setLoggedIn({
        accessToken: res.data.token,
        expiration: res.data.expiration,
      });
      console.log("Done calling login", res.data);
    } catch (err) {
      console.log("Error with login: ", err);
      return Promise.reject(err);
    } finally {
      this.loading.login = false;
    }
  }
  @Action({ rawError: true })
  async tryRegister(): Promise<void> {
    try {
      this.loading.register = true;
      const res = await axiosInstance
        .post("/api/Authenticate/register", {
          firstName: this.getRegisterCredentials.firstName,
          lastName: this.getRegisterCredentials.lastName,
          phoneNumber: this.getRegisterCredentials.phoneNumber,
          password: this.getRegisterCredentials.password,
          email: this.getRegisterCredentials.email,
        })
        .catch((err) => {
          //check types of errors heree
          if (err.isAxiosError) {
            const temp = err as AxiosError;
            if (temp.response)
              console.log(
                "Axios register error: ",
                Object.keys(temp.response),
                temp.response
              );
            return Promise.reject(temp);
          }
          return Promise.reject(err);
        });
      console.log("Done calling register", res.data);
    } catch (err) {
      console.log("Error with register: ", err);
      return Promise.reject(err);
    } finally {
      this.loading.register = false;
    }
  }
}
export default getModule(User);
