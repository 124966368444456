
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Station, StationsPerProvince } from "models";
import { DataTableHeader } from "vuetify";
import GDD from "@/components/GDD.vue";

import StationsModule, {
  gddModalType,
  isLoadingType,
  provinceRustType,
  provinceSettings,
  singleModalType,
  singleRadiationType,
  singleRainfallType,
  singleTemperatureType,
} from "@/store/modules/Stations";

// interface provinceSettings {
//   [key: string]: DataOptions;
// }

@Component({
  components: {
    GDD,
  },
})
export default class Stations extends Vue {
  allRustPicker = [];
  get gddModal(): gddModalType {
    return StationsModule.getGDDModal;
  }
  rustTab = null;
  get loading(): isLoadingType {
    return StationsModule.isLoading;
  }
  get provinceRust(): provinceRustType {
    return StationsModule.getProvinceRust;
  }
  get singleTemperature(): singleTemperatureType {
    return StationsModule.getSingleTemperature;
  }
  get singleRainfall(): singleRainfallType {
    return StationsModule.getSingleRainfall;
  }
  get singleRadiation(): singleRadiationType {
    return StationsModule.getSingleRadiation;
  }
  rangeRustHeaders: DataTableHeader[] = [
    { text: "Date", value: "date", align: "start", },
    { text: "Chance of Rust", value: "chanceOfRust" },
    {
      text: "Asian Soy Bean Rust Index",
      sortable: false,
      value: "asiainSoybeanRustIndex",
    },
    { text: "Daily temp index", value: "dailyTempIndex" },
    { text: "Total Rain", value: "totalRain" },
    { text: "Darkness Hours", value: "darknessHours" },
    { text: "Sunrise", value: "sunrise" },
    { text: "Sunset", value: "sunset" },
  ];

  get singleModal(): singleModalType {
    return StationsModule.getSingleModal;
  }
  get stations(): StationsPerProvince[] {
    return StationsModule.getStations;
  }
  get provinceSettings(): provinceSettings {
    return StationsModule.getProvinceSettings;
  }

  async requestProvinceRust(): Promise<void> {
    await StationsModule.loadProvinceRust().catch((err) => {
      return Promise.reject(err);
    });
  }

  updatePagination(item: any, pagination: any): void {
    console.log("update:pagination", pagination, item);
  }
  itemsPerPageArray = [-1, 3, 6, 9];
  // page = 1;
  province = 0;

  // get getStations() {}
  get getKeys(): string[] {
    return ["name", "owner"];
  }
  clickedRustModal(item: Station): void {
     console.log("Clicked",item.info.name);
    if (StationsModule.getSingleModal.station?.info.name != item.info.name) {
      console.log("If Clicked", item.info.name, StationsModule.getSingleModal.station?.info.name);
      StationsModule.setSingleModal({
        station: item,
        calculate: {
          data: [],
          date: StationsModule.getSingleModal.calculate.date,
          activePicker: false,
        },
        calculatePeriod: {
          data: [],
          dateFrom: StationsModule.getSingleModal.calculatePeriod.dateFrom,
          dateTo: StationsModule.getSingleModal.calculatePeriod.dateTo,
          activePicker: {
            to: false,
            from: false,
          },
        },
        loading: false,
        active: true,
      });

      StationsModule.changeSingleModalPeriodData([]);
      StationsModule.changeSingleRadiationData([]);
      StationsModule.changeSingleModalData([]);
      StationsModule.changeSingleTemperatureData([]);
      StationsModule.changeSingleRainfallData([]);
    } else {
      console.log("Else Clicked ", item.info.name);
      const temp = StationsModule.getSingleModal;
      temp.active = true;
      temp.loading = false;
      temp.calculate.activePicker = false;
      temp.calculatePeriod.activePicker.to = false;
      temp.calculatePeriod.activePicker.from = false;
      StationsModule.setSingleModal(temp);
    }
  }
  clickedGDDModal(item: Station): void {
    console.log("Clicked ", item.info.name);
    StationsModule.setGDDModal({
      ...this.gddModal,
      station: item,
      open: true,
      data: {
        labels: [],
        rainfall: [],
        GDD: [],
      },
    });
  }
  async getCalculation(): Promise<void> {
    await StationsModule.loadCalculation().catch((err) => {
      return Promise.reject(err);
    });
  }
  async getCalculationPeriod(): Promise<void> {
    await StationsModule.loadCalculationPeriod().catch((err) => {
      return Promise.reject(err);
    });
  }
  async getStations(): Promise<void> {
    await StationsModule.loadStations().catch((err) => {
      return Promise.reject(err);
    });
  }
  async mounted(): Promise<void> {
    if (this.getStations.length == 0) {
      await this.getStations();
    }
  }
  singleModalActive(singleModalActive: boolean): void {
    StationsModule.setSingleModalActive(singleModalActive);
  }
}
