






































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ResetPassword extends Vue {
  errorMsg = "Error Resetting Password: Please try again...";
  alert = false;
  error = false;
  valid = false;
  loading = false;
  email = null;
  pass = null;
  visible = {
    pass: false,
    confirm: false,
  };
  matchingPass = null;
  matchingPassRules = [
    (v: string): boolean | string =>
      (v && v.length >= 1) || "Matching password required",
  ];

  get passwordConfirmationRule(): (v: string) => string | boolean {
    return () =>
      (this.pass === this.matchingPass && this.pass != null) ||
      `Password must match ${this.pass}`;
  }
  rules = {
    required: (v: string): boolean | string =>
      (v && v.length >= 5) || "Password requires length of 5",
    lowercase: (v: string): boolean | string =>
      /(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    uppercase: (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    number: (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    specialChar: (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
  };
  emailRules = [
    (v: string): boolean | string => !!v || "E-mail is required",
    (v: string): boolean | string =>
      /.+@.+\..+/.test(v) || "E-mail must be valid",
  ];
  get confirmPassRules(): ((v: string) => string | boolean)[] {
    const temp = [
      this.rules.required,
      this.rules.lowercase,
      this.rules.uppercase,
      this.rules.number,
      this.rules.specialChar,
      this.passwordConfirmationRule,
    ];
    return temp;
  }
  passRules = [
    this.rules.required,
    this.rules.lowercase,
    this.rules.uppercase,
    this.rules.number,
    this.rules.specialChar,
  ];
  mounted(): void {
    if (
      this.$route.query.userId === undefined ||
      this.$route.query.code === undefined
    ) {
      this.error = true;
    }
  }
  async sendResetPass(): Promise<void> {
    try {
      this.loading = true;
      await this.$axiosNoAuth
        .post("api/Authenticate/resetPasswordCallback", {
          userId: this.$route.query.userId,
          code: this.$route.query.code,
          password: this.pass,
          email: this.email,
        })
        .catch((err) => {
          console.log("Error resetting password", err);
          return Promise.reject(err.response.data);
        });
      this.$router.push({
        name: "Login",
      });
    } catch (err) {
      console.log("Pushing error: ", err);
      if (err.isAxiosError) {
        this.errorMsg = err.toJSON().message;
      }
      this.alert = true;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
