import Auth from "@/store/modules/Auth";
import _Vue from "vue";
import axios, { AxiosInstance } from "axios";
import { configExport } from "@/config/config";
console.log("configExport: ", configExport.API_BASE_URL);

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: configExport.API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const axiosNoAuth: AxiosInstance = axios.create({
  baseURL: configExport.API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((restConfig) => {
  const u = Auth.isLoggedIn;
  if (u == true) {
    // is logged in
    const token = Auth.accessToken;
    console.log("ACces token :", token, u, Auth);
    const config = restConfig;
    config.headers.Authorization = "Bearer " + Auth.accessToken;
    return config;
  }
  return restConfig;
});

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$axiosInstance = axiosInstance;
    Vue.prototype.$axiosNoAuth = axiosNoAuth;
  },
};
