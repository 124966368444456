var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"show-arrows":"","background-color":"primary","dark":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{key:"prec"},[_vm._v("Precipitation Data")]),_c('v-tab',{key:"prec2"},[_vm._v("Precipitation Data 2")]),_c('v-tab',{key:"hail"},[_vm._v("Hail Data")]),_c('v-tab',{key:"temp"},[_vm._v("Temperature Data")])],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{key:"prec"},[_c('v-data-table',{attrs:{"items-per-page":-1,"group-by":"province","headers":_vm.headers,"items":_vm.precipitationData,"hide-default-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('v-btn',{attrs:{"small":"","icon":""}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}}])})],1),_c('v-tab-item',{key:"prec2"},[_c('v-data-table',{attrs:{"items-per-page":-1,"group-by":"province","headers":_vm.headers,"items":_vm.precipitationData2,"hide-default-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('v-btn',{attrs:{"small":"","icon":""}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}}])})],1),_c('v-tab-item',{key:"hail"},[_c('v-data-table',{attrs:{"items-per-page":-1,"group-by":"province","headers":_vm.headers,"items":_vm.hailData,"hide-default-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('v-btn',{attrs:{"small":"","icon":""}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}}])})],1),_c('v-tab-item',{key:"temp"},[_c('v-data-table',{staticClass:"table-striped",attrs:{"items-per-page":-1,"group-by":"province","headers":_vm.tempheaders,"items":_vm.temperatureData,"hide-default-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('v-btn',{attrs:{"small":"","icon":""}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_vm._v(" "+_vm._s(group)+" ")],1)]}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }