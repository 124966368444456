














































































































































import { Component, Vue } from "vue-property-decorator";
import Auth, { register } from "@/store/modules/Auth";

@Component
export default class RegisterMember extends Vue {
  errorMsg = "Error Registering member: Please try again...";
  registered = false;
  alert = false;
  valid = false;
  get getCredentials(): register {
    return Auth.getRegisterCredentials;
  }
  get getLoading(): boolean {
    return Auth.getLoading.register;
  }
  backToForm(): void {
    console.log("Back to form ", this.$refs);
    this.registered = false;
    try {
      const refForm: any = this.$refs.registerForm;
      refForm.reset();
      refForm.validate();
    } catch (err) {
      console.log("Ehh ", err);
    }
  }
  updateFirstName(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ firstName: val });
  }
  updateLastName(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ lastName: val });
  }
  updateEmail(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ email: val });
  }
  updatePhoneNumber(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ phoneNumber: val });
  }
  updatePassword(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ password: val });
  }
  updatePasswordConfirm(val: string): void {
    console.log("Test :", val);
    Auth.setRegisterCredentials({ passwordConfirm: val });
  }
  emailRules = [
    (v: string): boolean | string => !!v || "E-mail is required",
    (v: string): boolean | string =>
      /.+@.+\..+/.test(v) || "E-mail must be valid",
  ];

  get passwordConfirmationRule(): (v: string) => string | boolean {
    return () =>
      (this.getCredentials.password === this.getCredentials.passwordConfirm &&
        this.getCredentials.password != null) ||
      `Password must match ${this.getCredentials.password}`;
  }
  rules = {
    required: (v: string): boolean | string =>
      (v && v.length >= 5) || "Password requires length of 5",
    lowercase: (v: string): boolean | string =>
      /(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    uppercase: (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    number: (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    specialChar: (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
  };
  get confirmPassRules(): ((v: string) => string | boolean)[] {
    const temp = [
      this.rules.required,
      this.rules.lowercase,
      this.rules.uppercase,
      this.rules.number,
      this.rules.specialChar,
      this.passwordConfirmationRule,
    ];
    return temp;
  }
  passRules = [
    this.rules.required,
    this.rules.lowercase,
    this.rules.uppercase,
    this.rules.number,
    this.rules.specialChar,
  ];
  // matchingPassRules = [
  //   (v: string): boolean | string =>
  //     (v && v.length >= 1) || "Matching password required",
  //   (v: string): boolean | string =>
  //     (v && v == this.getCredentials.password) || "Password dont match",
  // ];
  // passwordRules = [
  //   (v: string): boolean | string =>
  //     (v && v.length >= 5) || "Password requires length of 5",
  //   (v: string): boolean | string =>
  //     /.(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
  //   (v: string): boolean | string =>
  //   /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
  //   (v: string): boolean | string =>
  //     /(?=.*\d)/.test(v) || "Requires atleast 1 number",
  //   (v: string): boolean | string =>
  //     /([!@$%])/.test(v) || "Requires atleast 1 special character",
  // ];

  async tryRegister(): Promise<void> {
    try {
      await Auth.tryRegister();
      this.registered = true;
    } catch (err) {
      if (err.isAxiosError) {
        this.errorMsg = err.toJSON().message;
      }
      this.alert = true;
      console.log("Error registering: ", Object.keys(err), err.toJSON());
    }
  }
  mounted(): void {
    if (this.$refs.registerForm) {
      (this.$refs.registerForm as any).validate();
    }
  }
}
