import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/components/RegisterMember.vue";
import Stations from "@/components/Stations.vue";
import Precipitations from "@/components/Precipitations.vue";
import ResetPass from "@/views/ResetPassword.vue";
import ConfirmEmail from "@/views/ConfirmEmail.vue";
import AuthModule from "@/store/modules/Auth";
import Auth from "@/store/modules/Auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: "/ResetPassword/userId/:userId/code/:code",
  //   name: "ResetPassword",
  //   component: ResetPass,
  //   props: true,
  // },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: ResetPass,
    props: true,
  },
  {
    path: "/ConfirmEmail",
    name: "ConfirmEmail",
    component: ConfirmEmail,
    props: true,
  },
  // {
  //   path: "/ConfirmEmail/userId/:userId/code/:code",
  //   name: "ConfirmEmail",
  //   component: ConfirmEmail,
  //   props: true
  // },
  {
    meta: {
      title: "Pioneer Plant Sickness",
    },
    path: "/",
    redirect: "/login",
  },
  {
    meta: {
      title: "Pioneer Plant Sickness",
    },
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    meta: {
      title: "Pioneer Plant Sickness",
    },
    path: "/dashboard",
    component: () => import("@/views/Dashboard.vue"),
    children: [
      {
        meta: {
          title: "Pioneer Plant Sickness",
        },
        path: "weather-stations",
        name: "dash.stations",
        component: Stations,
      },
      {
        meta: {
          title: "Pioneer Plant Sickness",
        },
        path: "precipitation",
        name: "dash.precipitation",
        component: Precipitations,
      },
      {
        meta: {
          title: "Pioneer Plant Sickness",
        },
        path: "register-new-member",
        name: "dash.register",
        component: Register,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Pioneer Plant Sickness";

  if (to.name === "ResetPassword" || to.name === "ConfirmEmail") {
    next();
    return;
  }

  // if (AuthModule.isLoggedIn == false) {
  const key = localStorage.getItem("plant-sickness/login");
  if (key != null) {
    AuthModule.setLoggedIn(JSON.parse(key));
  } else {
    Auth.removeLoggedIn();
  }
  // }

  if (to.name !== "Login" && AuthModule.isLoggedIn == false) {
    next({
      name: "Login",
      params: {
        from: from == undefined ? "" : from.path,
      },
    });
  } else if (to.name == "Login" && AuthModule.isLoggedIn == true) {
    next({
      name: "dash.stations",
    });
  } else next();
});
export default router;
