import _Vue from "vue";

const env: string = process.env.NODE_ENV || "development";

interface configsModel {
  [key: string]: config;
}
interface config {
  VUE_APP_BASE_URL: string;
  API_BASE_URL: string;
}

import dev from "./development";
import production from "./production";
import staging from "./staging";

const configs: configsModel = {
  development: dev,
  production: production,
  staging: staging,
};
console.log("My Config ", configs);

const envConfig: config = configs[env];
//for plugins
//for vue.use
export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$config = envConfig;
  },
};
const configExport = envConfig;

export { configExport, config };
