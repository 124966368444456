













import { Component, Vue } from "vue-property-decorator";
import Auth from "@/store/modules/Auth";

@Component
export default class App extends Vue {
  alert = true;
  icons = ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"];
}
