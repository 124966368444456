















































































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import StationsModule, {
  Precipitation,
  PrecipitationData,
  TemperatureData,
} from "@/store/modules/Stations";

@Component
export default class Precipitations extends Vue {
  tabs = null;
  headers: DataTableHeader[] = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Province",
      align: "start",
      value: "province",
    },
    {
      text: "Today",
      align: "start",
      value: "today",
    },
    {
      text: "Day 1",
      align: "start",
      value: "day1",
    },
    {
      text: "Day 2",
      align: "start",
      value: "day2",
    },
    {
      text: "Day 3",
      align: "start",
      value: "day3",
    },
    {
      text: "Day 4",
      align: "start",
      value: "day4",
    },
    {
      text: "Day 5",
      align: "start",
      value: "day5",
    },
    {
      text: "Day Total",
      align: "start",
      value: "dayTotal",
    },
    {
      text: "Day 14 Total",
      align: "start",
      value: "day14Total",
    },
    {
      text: "Day 30 Total",
      align: "start",
      value: "day30Total",
    },
    {
      text: "Season Total",
      align: "start",
      value: "seasonTotal",
    },
    {
      text: "Previous Season Total",
      align: "start",
      value: "prevSeasonTotal",
    },
    {
      text: "Previous Season Total To Date",
      align: "start",
      value: "prevSeasonTotalToDate",
    },
  ];
  tempheaders: DataTableHeader[] = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Type ID",
      align: "start",
      value: "typeID",
    },
    {
      text: "Province",
      align: "start",
      value: "province",
    },
    {
      text: "Day 1 Min",
      align: "start",
      value: "day1Min",
    },
    {
      text: "Day 1 Max",
      align: "start",
      value: "day1Max",
    },
    {
      text: "Day 2 Min",
      align: "start",
      value: "day2Min",
    },
    {
      text: "Day 2 Max",
      align: "start",
      value: "day2Max",
    },
    {
      text: "Day 3 Min",
      align: "start",
      value: "day3Min",
    },
    {
      text: "Day 3 Max",
      align: "start",
      value: "day3Max",
    },
    {
      text: "Day 4 Min",
      align: "start",
      value: "day4Min",
    },
    {
      text: "Day 4 Max",
      align: "start",
      value: "day4Max",
    },

    {
      text: "Day 5 Min",
      align: "start",
      value: "day5Min",
    },
    {
      text: "Day 5 Max",
      align: "start",
      value: "day5Max",
    },

    {
      text: "Day 6 Min",
      align: "start",
      value: "day6Min",
    },
    {
      text: "Day 6 Max",
      align: "start",
      value: "day6Max",
    },
    {
      text: "Day 7 Min",
      align: "start",
      value: "day7Min",
    },
    {
      text: "Day 7 Max",
      align: "start",
      value: "day7Max",
    },
  ];
  get getPercipitation(): Precipitation | null {
    return StationsModule.getPrecipitation;
  }
  get precipitationData(): PrecipitationData[] {
    if (this.getPercipitation == null) {
      return [];
    } else {
      return this.getPercipitation.precipitationData;
    }
  }
  get precipitationData2(): PrecipitationData[] {
    if (this.getPercipitation == null) {
      return [];
    } else {
      return this.getPercipitation.precipitationData2;
    }
  }
  get hailData(): PrecipitationData[] {
    if (this.getPercipitation == null) {
      return [];
    } else {
      return this.getPercipitation.hailData;
    }
  }
  get temperatureData(): TemperatureData[] {
    if (this.getPercipitation == null) {
      return [];
    } else {
      return this.getPercipitation.temperatureData;
    }
  }
  async loadPrecipitations(): Promise<void> {
    await StationsModule.loadPrecipitations().catch((err) => {
      return Promise.reject(err);
    });
  }
  async mounted(): Promise<void> {
    await this.loadPrecipitations();
  }
}
