import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import {
  Station,
  StationsPerProvince,
  rustDataWithName,
  readingInfo,
} from "models";
import { DataOptions, DataTableHeader } from "vuetify";
import { axiosInstance } from "@/plugins/axios";

export interface Precipitation {
  precipitationData: PrecipitationData[];
  precipitationData2: PrecipitationData[];
  availableStations: string[];
  hailData: PrecipitationData[];
  temperatureData: TemperatureData[];
}

export interface PrecipitationData {
  ID: string;
  Name: string;
  Province: string;
  Today: number;
  Day1: number;
  Day2: number;
  Day3: number;
  Day4: number;
  Day5: number;
  DayTotal: number;
  Day14Total: number;
  Day30Total: number;
  SeasonTotal: number;
  PrevSeasonTotal: number;
  PrevSeasonTotalToDate: number;
}

export interface TemperatureData {
  ID: string;
  Name: string;
  TypeID: string;
  Province: string;
  Day1Min: number;
  Day1Max: number;
  Day2Min: number;
  Day2Max: number;
  Day3Min: number;
  Day3Max: number;
  Day4Min: number;
  Day4Max: number;
  Day5Min: number;
  Day5Max: number;
  Day6Min: number;
  Day6Max: number;
  Day7Min: number;
  Day7Max: number;
}

export interface isLoadingType {
  single: boolean;
  period: boolean;
  precipitation: boolean;
  stations: boolean;
  gdd: boolean;
}
export interface gddModalType {
  open: boolean;
  to: {
    date: string;
    activePicker: boolean;
  };
  from: {
    date: string;
    activePicker: boolean;
  };
  data: {
    rainfall: readingInfo[];
    GDD: readingInfo[];
    labels: string[];
  };
  station: Station | null;
}
interface tempInfo {
  date: string;
  hoursOfDarkness: number;
  sumOfHourlyIndices: number;
  sunrise: string;
  sunset: string;
}
interface rainfallInfo {
  sum: number;
  totalRainfallDay: number;
  asianSoybeanRustIndex: number;
}
export interface singleTemperatureType {
  headers: DataTableHeader[];
  data: any[];
  info: tempInfo;
}
export interface singleRadiationType {
  headers: DataTableHeader[];
  data: any[];
}
export interface singleModalType {
  loading: boolean;
  active: boolean;
  station: Station | null;
  calculate: {
    date: string;
    data: [];
    activePicker: boolean;
  };
  calculatePeriod: {
    dateFrom: string;
    dateTo: string;
    data: [];
    activePicker: {
      to: boolean;
      from: boolean;
    };
  };
}
export interface singleRainfallType {
  headers: DataTableHeader[];
  data: any[];
  info: rainfallInfo;
}
export interface provinceSettings {
  [key: string]: DataOptions;
}
export interface provinceRustType {
  data: rustDataWithName[];
  province: string;
  loading: boolean;
  overlay: boolean;
  headers: DataTableHeader[];
  loaded: number;
  openFailedStations: boolean;
  failedStations: {
    message: string;
    error: number;
    name: string;
  }[];
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Stations",
  store,
})
class Stations extends VuexModule {
  defaultSetting: DataOptions = {
    page: 1,
    sortDesc: [true],
    sortBy: ["info.name"],
    itemsPerPage: -1,
    groupBy: [],
    groupDesc: [],
    multiSort: true,
    mustSort: true,
  };
  yesterday: string = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).toISOString();
  gddModal: gddModalType = {
    open: false,
    to: {
      date: this.yesterday.split("T")[0],
      activePicker: false,
    },
    from: {
      date: "2021-07-01",
      activePicker: false,
    },
    data: {
      rainfall: [],
      GDD: [],
      labels: [],
    },
    station: null,
  };
  
  stations: StationsPerProvince[] = [];
  provinceSettings: provinceSettings = {};
  loading: isLoadingType = {
    single: false,
    period: false,
    stations: false,
    precipitation: true,
    gdd: false,
  };
  precipitation: Precipitation | null = null;
  provinceRust: provinceRustType = {
    openFailedStations: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Date",
        align: "start",
        value: "dailyTemperatureIndex.date",
      },
      {
        text: "Sum",
        align: "start",
        value: "sum",
      },
      {
        text: "Rust Index",
        align: "start",
        value: "asianSoybeanRustIndex.asianSoybeanRustIndex",
      },
      {
        text: "Rainfall",
        align: "start",
        value: "totalRainfallDay",
      },
      {
        text: "Hrs of darkness",
        align: "start",
        value: "dailyTemperatureIndex.hoursOfDarkness",
      },
      {
        text: "Sum of HI",
        align: "start",
        value: "dailyTemperatureIndex.sumOfHourlyIndices",
      },
      {
        text: "Sunrise",
        align: "start",
        value: "dailyTemperatureIndex.sunrise",
      },
      {
        text: "Sunset",
        align: "start",
        value: "dailyTemperatureIndex.sunset",
      },
    ],
    data: [],
    province: "",
    loading: false,
    overlay: false,
    loaded: 0,
    failedStations: [],
  };
  singleModal: singleModalType = {
    loading: false,
    active: false,
    station: null,
    calculate: {
      date: this.yesterday.split("T")[0],
      data: [],
      activePicker: false,
    },
    calculatePeriod: {
      dateFrom: new Date(
        new Date(this.yesterday).setDate(new Date(this.yesterday).getDate() - 1)
      )
        .toISOString()
        .split("T")[0],
      dateTo: this.yesterday.split("T")[0],
      data: [],
      activePicker: {
        to: false,
        from: false,
      },
    },
  };
  singleRadiation: singleRadiationType = {
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "dateTime",
      },
      {
        text: "Avg Reading",
        sortable: false,
        value: "avgReading",
      },
      {
        text: "Min Reading",
        sortable: false,
        value: "minReading",
      },
      {
        text: "Max Reading",
        sortable: false,
        value: "maxReading",
      },
    ],
    data: [],
  };
  singleTemperature: singleTemperatureType = {
    headers: [
      {
        text: "Hour",
        align: "start",
        sortable: false,
        value: "hour",
      },
      {
        text: "Darkness Indicator",
        align: "start",
        sortable: false,
        value: "darknessIndicator.indicator",
      },
      {
        text: "Temperature",
        sortable: false,
        value: "temperatureFactor.temperature",
      },
      {
        text: "Temperature Factor",
        sortable: false,
        value: "temperatureFactor.factor",
      },
    ],
    data: [],
    info: {
      date: new Date("1970-01-01").toISOString().split("T")[0],
      hoursOfDarkness: 0,
      sumOfHourlyIndices: 0,
      sunrise: new Date("1970-01-01").toISOString().split("T")[0],
      sunset: new Date("1970-01-01").toISOString().split("T")[0],
    },
  };
  singleRainfall: singleRainfallType = {
    headers: [
     /*  {
        text: "Date",
        sortable: false,
        value: "dateTime",
      }, */
      {
        text: "Avg Reading",
        align: "start",
        sortable: false,
        value: "avgReading",
      },
      {
        text: "Min Reading",
        sortable: false,
        value: "minReading",
      },
      {
        text: "Max Reading",
        sortable: false,
        value: "maxReading",
      },
    ],
    data: [],
    info: {
      sum: 0,
      totalRainfallDay: 0,
      asianSoybeanRustIndex: 0,
    },
  };
  get isLoading(): isLoadingType {
    return this.loading;
  }
  get getProvinceRust(): provinceRustType {
    return this.provinceRust;
  }
  get getSingleTemperature(): singleTemperatureType {
    return this.singleTemperature;
  }
  get getSingleRainfall(): singleRainfallType {
    return this.singleRainfall;
  }
  get getSingleRadiation(): singleRadiationType {
    return this.singleRadiation;
  }
  get getSingleModal(): singleModalType {
    return this.singleModal;
  }
  get getStations(): StationsPerProvince[] {
    return this.stations;
  }
  get getProvinceSettings(): provinceSettings {
    return this.provinceSettings;
  }
  @Mutation
  setSingleModal(modal: singleModalType) {
    this.singleModal = modal;
  }
  @Mutation
  setSingleModalActive(modal: boolean) {
    this.singleModal.active = modal;
  }
  @Mutation
  changeSingleModalPeriodData(arr: any) {
    this.singleModal.calculatePeriod.data = arr;
  }
  @Mutation
  changeSingleModalData(arr: any) {
    this.singleModal.calculate.data = arr;
  }
  @Mutation
  clearStations() {
    this.stations = [];
  }
  @Mutation
  clearProvinceSettings() {
    this.provinceSettings = {};
  }

  @Mutation
  addProvinceSetting(prov: provinceSettings) {
    this.provinceSettings = {
      ...this.provinceSettings,
      ...prov,
    };
  }
  @Mutation
  addProvAndStation(prov: StationsPerProvince) {
    const temp = this.stations.find((pr) => pr.province == prov.province);
    if (!temp) {
      this.stations.push(prov);
    }
  }
  @Mutation
  changeSingleTemperatureData(arr: any) {
    this.singleTemperature.data = arr;
  }
  @Mutation
  changeSingleTemperatureInfo(info: tempInfo) {
    this.singleTemperature.info = info;
  }
  @Mutation
  changeSingleRadiationData(arr: any) {
    this.singleRadiation.data = arr;
  }
  @Mutation
  changeSingleRainfallInfo(info: rainfallInfo) {
    this.singleRainfall.info = info;
  }
  @Mutation
  changeSingleRainfallData(arr: any) {
    this.singleRainfall.data = arr;
  }

  @Mutation
  addStation(stat: Station) {
    const temp = this.stations.find(
      (prov) => prov.province === stat.info.province.name
    );
    // console.log("Add station: ", stat, temp);
    if (temp) {
      console.log("Add ", stat.info.name);
      temp.stations.push(stat);
    }
  }
  @Mutation
  setPrecipitation(pre: Precipitation) {
    this.precipitation = pre;
  }
  get getPrecipitation(): Precipitation | null {
    return this.precipitation;
  }

  @Action
  async loadPrecipitations(): Promise<void> {
    try {
      this.loading.precipitation = true;
      const res = await axiosInstance
        .get("api/station/getPrecipitation")
        .catch((err) => {
          return Promise.reject(err);
        });

      const data = res.data.result;
      this.setPrecipitation(data);

      console.log("Res ", res.data);
    } catch (err) {
      console.log("Cant get Precipitations: ", err);
    } finally {
      this.loading.precipitation = false;
    }
  }
  @Action
  async loadCalculation(): Promise<void> {
    try {
      this.loading.single = true;
      const res = await axiosInstance
        .get("/api/SoyBeanRust/getCalculation", {
          params: {
            date: this.singleModal.calculate.date,
            uniqueKey: this.singleModal.station?.uniqueKey,
          },
        })
        .catch((err) => {
          return Promise.reject(err);
        });

      console.log("Get Calculations: ", res);
      this.changeSingleTemperatureData(
        res.data.response.dailyTemperatureIndex.hourlyIndices
      );
      this.changeSingleTemperatureInfo({
        date: res.data.response.dailyTemperatureIndex.date as string,
        hoursOfDarkness: res.data.response.dailyTemperatureIndex
          .hoursOfDarkness as number,
        sunrise: res.data.response.dailyTemperatureIndex.sunrise as string,
        sunset: res.data.response.dailyTemperatureIndex.sunset as string,
        sumOfHourlyIndices: res.data.response.dailyTemperatureIndex
          .sumOfHourlyIndices as number,
      });
      this.changeSingleRainfallData(
        res.data.response.asianSoybeanRustIndex.rainDayData
      );
      this.changeSingleRainfallInfo({
        sum: res.data.response.sum,
        totalRainfallDay: res.data.response.totalRainfallDay,
        asianSoybeanRustIndex:
          res.data.response.asianSoybeanRustIndex.asianSoybeanRustIndex,
      });
      this.changeSingleRadiationData(res.data.response.radiationData);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.single = false;
    }
  }
  get getGDDModal() {
    return this.gddModal;
  }
  @Mutation
  setGDDModal(modal: gddModalType) {
    this.gddModal = modal;
  }
  @Mutation
  setGDDModalOpen(modal: boolean) {
    this.gddModal.open = modal;
  }

  @Action
  async loadGDD(): Promise<void> {
    try {
      this.loading.gdd = true;
      this.loading.gdd = true;
      const res = await axiosInstance
        .get("/api/station/getStationsGddData", {
          params: {
            startDate: this.gddModal.from.date,
            endDate: this.gddModal.to.date,
            stationId: this.gddModal.station?.station_id,
          },
        })
        .catch((err) => {
          return Promise.reject(err);
        });

      console.log("Get GDD Data: ", res);
      this.gddModal.data.GDD = res.data.result.seasonalGDD;
      this.gddModal.data.rainfall = res.data.result.seasonalRainfall;
      const labels: string[] = [];
      res.data.result.seasonalGDD.forEach((element: readingInfo) => {
        const date = new Date(element.dateTime).toLocaleDateString();
        if (date) labels.push(date);
      });
      this.gddModal.data.labels = labels;

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.gdd = false;
    }
  }
  @Action
  async loadCalculationPeriod(): Promise<void> {
    try {
      this.loading.period = true;
      const res = await axiosInstance
        .get("/api/SoyBeanRust/getCalculatePeriod", {
          params: {
            dateFrom: this.singleModal.calculatePeriod.dateFrom,
            dateTo: this.singleModal.calculatePeriod.dateTo,
            uniqueKey: this.singleModal.station?.uniqueKey,
          },
        })
        .catch((err) => {
          return Promise.reject(err);
        });

      console.log("Get Calculations Period: ", res);
      this.changeSingleModalPeriodData(res.data.response);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.period = false;
    }
  }
  @Action
  async loadStations(): Promise<void> {
    try {
      this.loading.stations = true;
      const res = await axiosInstance
        .get("/api/station/getStationsOverview")
        .catch((err) => {
          return Promise.reject(err);
        });
      const data = res.data.result as Station[];
      // this.stations = this.mockStations;
      this.clearStations();
      this.clearProvinceSettings();
      data.forEach((element) => {
        // console.log("for each station", this.getProvinceSettings[element.info.province.name]);
        const found = this.stations.find(
          (stat) => element.info.province.name == stat.province
        );

        if (found == undefined) {
          const temp = {
            [element.info.province.name]: {
              ...this.defaultSetting,
            },
          };
          this.addProvinceSetting(temp);
          const newStation: StationsPerProvince = {
            province: element.info.province.name,
            stations: [element],
          };
          this.addProvAndStation(newStation);
        } else {
          this.stations.forEach((prov) => {
            // console.log("Prov: ", element.info.province.name, prov.province, prov.province == element.info.province.name);
            if (prov.province == element.info.province.name) {
              this.addStation(element);
              // inner.stations.push(element);
            }
          });
        }
      });
      console.log("Res ", res.data.result);
    } catch (err) {
      console.log("Cant get stations: ", err);
    } finally {
      this.loading.stations = false;
    }
  }
  @Action
  async loadProvinceRust(): Promise<void> {
    try {
      this.provinceRust.overlay = true;
      this.provinceRust.loading = true;
      this.provinceRust.data = [];
      let length = 0;
      this.stations.forEach((element) => {
        length += element.stations.length;
      });
      let index = 0;
      for await (const prov of this.stations) {
        for await (const stat of prov.stations) {
          if (stat.info.type.type.includes("XLog") == true) {
            const res = await axiosInstance
              .get("/api/SoyBeanRust/getCalculation", {
                params: {
                  date: this.singleModal.calculate.date,
                  uniqueKey: stat.uniqueKey,
                },
              })
              .catch((err) => {
                if (err.isAxiosError == true) {
                  // console.log("err", Object.keys(err.response), err.response);
                  console.log("Err 500 ", stat.info.name);
                  this.provinceRust.failedStations.push({
                    name: stat.info.name,
                    error: err.response.status,
                    message: err.response.data,
                  });
                  return Promise.resolve(true);
                }
                return Promise.reject(err);
              });
            // do stuff with the data here inside res.data.response!
            if (typeof res !== "boolean") {
              console.log(stat.info.name, res.data.response);
              this.provinceRust.data.push({
                ...res.data.response,
                name: stat.info.name,
                province: prov.province,
              } as rustDataWithName);
            }
          }
          index++;
          this.provinceRust.loaded = (index / length) * 100;
        }
        console.log(prov.province);
      }
      return Promise.resolve();
    } catch (err) {
      console.log("Error requestProvinceRust: ", err);
      return Promise.reject(err);
    } finally {
      this.provinceRust.loading = true;
    }
  }
}

export default getModule(Stations);
