




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Chart, ChartData, ChartDataset, registerables } from "chart.js";
import StationsModule, { gddModalType } from "@/store/modules/Stations";

import { readingInfo } from "models";

Chart.register(...registerables);

@Component
export default class GDDGraphs extends Vue {
  // @Prop({ default: [] }) readonly labels!: Array<string>;
  // @Prop({ default: [] }) readonly data!: Array<number>;
  // @Prop({ default: [] }) readonly colors!: string[];
  colors = [
    "rgb(255, 0, 0)",
    "rgb(0,255, 0)",
    "rgb(0, 0, 255)",
    "rgb(0, 255, 255)",
  ];
  get getGDD(): gddModalType {
    return StationsModule.getGDDModal;
  }
  @Watch("gddDataMinTemp", { immediate: true, deep: true })
  changedMinTemp(val: number[], oldVal: any): void {
    console.log("Changed minTemp ", val, oldVal);
    if (val.length > 0) this.createChart();
  }
  @Watch("gddDataMaxTemp", { immediate: true, deep: true })
  changedMaxTemp(val: number[], oldVal: any): void {
    console.log("Changed maxTemp ", val, oldVal);
    if (val.length > 0) this.createChart();
  }
  @Watch("gddDataMinTemp", { immediate: true, deep: true })
  changedRainfall(val: number[], oldVal: any): void {
    console.log("Changed rainfall ", val, oldVal);
    if (val.length > 0) this.createChart();
  }
  @Watch("gddDataAvgTemp", { immediate: true, deep: true })
  changedGDD(val: number[], oldVal: any): void {
    console.log("Changed GDD ", val, oldVal);
    if (val.length > 0) this.createChart();
  }
  get gddDataRainfall(): number[] {
    const vals: number[] = [];
    this.getGDD.data.rainfall.forEach((element: readingInfo) => {
      vals.push(element.minReading);
    });
    return vals;
  }
  get gddDataMinTemp(): number[] {
    const vals: number[] = [];
    this.getGDD.data.GDD.forEach((element: readingInfo) => {
      vals.push(element.minReading);
    });
    return vals;
  }
  get gddDataAvgTemp(): number[] {
    const vals: number[] = [];
    this.getGDD.data.GDD.forEach((element: readingInfo) => {
      vals.push(element.avgReading);
    });
    return vals;
  }
  get gddDataMaxTemp(): number[] {
    const vals: number[] = [];
    this.getGDD.data.GDD.forEach((element: readingInfo) => {
      vals.push(element.maxReading);
    });
    return vals;
  }
  mounted() {
    if (this.gddDataMaxTemp.length > 0) {
      this.createChart();
    }
  }
  get getDatasets(): ChartDataset[] {
    const temp = [
      {
        label: "Period GDD",
        data: this.gddDataAvgTemp,
        fill: false,
        borderColor: this.colors[0] ? this.colors[0] : "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Period Max Temp",
        data: this.gddDataMaxTemp,
        fill: false,
        borderColor: this.colors[1] ? this.colors[1] : "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Period Min Temp",
        data: this.gddDataMinTemp,
        fill: false,
        borderColor: this.colors[2] ? this.colors[2] : "rgb(0, 192, 255)",
        tension: 0.1,
      },
      {
        label: "Rainfall Period",
        data: this.gddDataRainfall,
        fill: false,
        borderColor: this.colors[3] ? this.colors[3] : "rgb(0, 192, 255)",
        tension: 0.1,
      },
    ];
    return temp;
  }

  createChart(): void {
    console.log("Create chart");
    const canvas = document.getElementById("gddGraph") as HTMLCanvasElement;
    new Chart(canvas, {
      type: "line",
      data: {
        labels: this.getGDD.data.labels,
        datasets: this.getDatasets,
      },
      options: {
        scales: {
          x: {
            beginAtZero: true,
          },
        },
      },
    });
  }
}
