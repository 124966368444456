













































































































































































































import { Component, Vue } from "vue-property-decorator";
import StationsModule, { gddModalType, isLoadingType } from "@/store/modules/Stations";
import { DataTableHeader } from "vuetify";
import { readingInfo } from "models";
import GDDGraphs from "@/components/GDDGraphs.vue";

@Component({
  components: {
    GDDGraphs,
  },
})
export default class GDD extends Vue {
  // to date = current date
  // from date = last july 1
  tempHeaders: DataTableHeader[] = [
    {
      text: "Date",
      align: "start",
      sortable: false,
      value: "dateTime",
    },
    { text: "Daily Heat Units", value: "avgReading" },
    { text: "Min Reading", value: "minReading" },
    { text: "Max Reading", value: "maxReading" },
    { text: "Unit", value: "unit" },
  ];
  rainHeaders: DataTableHeader[] = [
    {
      text: "Date",
      align: "start",
      sortable: false,
      value: "dateTime",
    },
    { text: "Avg Reading", value: "avgReading" },
    { text: "Min Reading", value: "minReading" },
    { text: "Max Reading", value: "maxReading" },
    { text: "Unit", value: "unit" },
  ];
  tabs = null;
  get gddModal(): gddModalType {
    return StationsModule.getGDDModal;
  }
  get loading(): isLoadingType {
    return StationsModule.isLoading;
  }
  get gddDataAvgTemp(): number[] {
    const vals: number[] = [];
    this.gddModal.data.GDD.forEach((element: readingInfo) => {
      vals.push(element.avgReading);
    });
    return vals;
  }
  get computedGDD(): number {
    if (this.gddDataAvgTemp.length > 0) {
      let temp = 0;
      this.gddDataAvgTemp.forEach((element: number) => {
        temp += element;
      });
      return temp;
    } else {
      return -1;
    }
  }
  async loadGDD(): Promise<void> {
    await StationsModule.loadGDD().catch((err) => {
      //add notifications here
      return Promise.reject(err);
    });
  }
  setGDDModalOpen(gddModal: boolean): void {
    StationsModule.setGDDModalOpen(gddModal);}
}
